<template>
  <CRow>
    <CCol sm="12">
      <ListForm
        :headerTitle="$t('pages.warehouse.inventoryProductListing')"
        :onSearch="onSearch">
        <template v-slot:searchBox>
            <CForm>
                <CRow>
                    <CCol md="7">
                        <div role="group" class="form-group form-row">
                            <label class="col-form-label col-sm-3">
                                {{ $t("common.products") }}
                            </label>
                            <div class="col-sm-9">
                                <multiselect :options="products"
                                             v-model="searchInfo.selectedProducts"
                                             :loading="isLoadingProducts"
                                             :searchable="true"
                                             :close-on-select="true"
                                             :clear-on-select="false"
                                             :multiple="true"
                                             :placeholder="$t('common.typetosearch')"
                                             :noOptionsText="$t('common.theListIsEmpty')"
                                             :reset-after="false"
                                             :local-search="false"
                                             :internal-search="false"
                                             @search-change="asyncFind"
                                             id="ajax"
                                             label="name"
                                             track-by="id">
                                </multiselect>
                            </div>
                        </div>
                    </CCol>
                </CRow>
                <CRow v-if="showAgentList">
                    <CCol sm="7">
                        <div role="group" class="form-group form-row">
                            <label class="col-sm-3 col-form-label">{{ $t('pages.dashboard.agents') }}</label>
                            <div class="col-sm-9 input-group">
                                <multiselect :options="agents"
                                             v-model="searchInfo.selectedAgents"
                                             :loading="isLoadingAgents"
                                             :searchable="true"
                                             :close-on-select="true"
                                             :clear-on-select="false"
                                             :multiple="false"
                                             :placeholder="$t('common.typetosearch')"
                                             :noOptionsText="$t('common.theListIsEmpty')"
                                             :reset-after="false"
                                             :local-search="false"
                                             @search-change="asyncFindAgency"
                                             :internal-search="false"
                                             id="ajax"
                                             label="name"
                                             track-by="id">
                                </multiselect>
                            </div>
                        </div>
                    </CCol>
                </CRow>
                <CRow>
                    <CCol sm="7">
                        <div role="group" class="form-group form-row">
                            <label class="col-sm-3 col-form-label">Tìm sản phẩm có hàng trong kho</label>
                            <div class="col-sm-9 input-group">
                                <CSwitch :key="onlyAvaiableProductInStock"
                                         color="info"
                                         :checked.sync="onlyAvaiableProductInStock"
                                         size="sm" />
                            </div>
                        </div>
                    </CCol>
                </CRow>
            </CForm>
        </template>
        <template v-slot:list>
            <div class="col-md-10">
                <span><b><label>Tổng số tiền:</label></b> </span><span style="padding-left:5px;color:crimson;font-weight:bold">{{ $func.formatCurrency(totalAmount) }}</span>               
            </div>
            <div class="col-md-10" v-if="showPurchasePrice">                              
                <span><b><label>Tổng tiền theo giá nhà phân phối:</label></b> </span><span style="padding-left:5px;color:crimson;font-weight:bold">{{ $func.formatCurrency(totalAmountByDistributorPrice) }}</span>
            </div>
            <CDataTable :items="inventoryProductList"
                        :fields="fields"
                        :noItemsView="{ noItems: $t('common.nodatafound') }"
                        hover
                        striped
                        border
                        small
                        fixed
                        @page-change="onPageChanged"
                        :loading="isLoading"
                        :items-per-page="itemsPerPage">
                <template #product_picture="{item}">
                    <td>
                        <img :src="item.product.defaultPictureModel.imageUrl" width="50" height="50" v-if="item.product.defaultPictureModel != null && item.product.defaultPictureModel.imageUrl" />
                        <img :src="'/content/images/no-image.gif'" width="50" height="50" v-else>
                    </td>
                </template>
                <template #product_name="{item}">
                    <td>
                        {{ item.product.name }}
                    </td>
                </template>
                <template #product_PurchasePrice_Text="{item}" v-if="showPurchasePrice">
                    <td>
                        {{ $func.formatNumber(item.product.purchasePrice) }}
                    </td>
                </template>
                <template #product_WholesalePrice_Text="{item}">
                    <td>
                        {{ $func.formatNumber(item.product.wholesalePrice) }}
                    </td>
                </template>
                <template #agent_name="{item}">
                    <td>
                        {{ item.agent.name }}
                    </td>
                </template>
                <template #quantity_text="{item}">
                    <td style="padding:0px">
                        <ul class="list-group"
                            v-if="item.productQuantities != null && item.productQuantities.length > 0">
                            <li class="list-group-item" v-for="pq in item.productQuantities" :key="pq.id">
                                {{$func.formatNumber(pq.quantity)}} {{pq.unit.name}}<span v-if="pq.frozenQuantity > 0">, Tạm khóa: <span class="text-danger">{{$func.formatNumber(pq.frozenQuantity)}}</span></span>
                            </li>
                        </ul>
                    </td>
                </template>
                <template #updatedDate_text="{item}">
                    <td class="py-2">
                        <label v-text="showLastUpdate(item.productQuantities)" />
                    </td>
                </template>
                <template #actions="{item}">
                    <td class="py-2">
                        <input type="button"
                               @click="onEditProductInStock(item)"
                               value="Cập nhật số lượng" :disabled="!showUpdateProductInStock" />
                    </td>
                </template>
            </CDataTable>
            <CPagination :pages="totalPages"
                         :activePage="pageIndex + 1"
                         @update:activePage="onPageChanged"></CPagination>
        </template>
      </ListForm>
    </CCol>
  </CRow>
</template>

<script>
    import i18n from "@/plugins/i18n";
    import ListForm from "@/components/ListForm.vue";
    import { Role } from '@/helpers/role';

    import { mapState, mapActions, mapGetters } from "vuex";
    export default {
        name: "InventoryList",
        data() {
            return {
                products: [],
                isLoadingProducts: false,
                showAgentList: true,
                showPurchasePrice: false,
                agents: [],
                isLoadingAgents: false,
                showUpdateProductInStock: true,
                onlyAvaiableProductInStock: false,
                itemsPerPage: 10,
                //fields: [
                //    { key: "product_picture", label: i18n.t("pages.product.picture") },
                //    { key: "product_name", label: i18n.t("pages.product.productName") },
                //    { key: "product_WholesalePrice_Text", label: i18n.t("pages.product.buyWholesalePrice") },
                //    { key: "agent_name", label: i18n.t("common.agent") },
                //    { key: "quantity_text", label: i18n.t("common.quantity") },
                //    { key: "updatedDate_text", label: i18n.t("pages.warehouse.lastUpdatedDate") },
                //    { key: "actions", label: "Thao tác" },
                //],
            };
        },
        components: {
            ListForm,
        },
        computed: {
            ...mapState("inventory", [
                "searchInfo",
                "isLoading",
                "inventoryProductList",
                "totalPages",
                "pageIndex", 'totalAmount', 'totalAmountByDistributorPrice'
            ]),            
            ...mapGetters('auth', ['user']),
            fields: {
                get() {
                    let _fields;                   
                    if (this.user != null && this.user.role === Role.Admin) {
                        _fields = [
                            { key: "product_picture", label: i18n.t("pages.product.picture") },
                            { key: "product_name", label: i18n.t("pages.product.productName") },
                            { key: "product_PurchasePrice_Text", label: i18n.t("pages.product.distributionPrice") },
                            { key: "product_WholesalePrice_Text", label: i18n.t("pages.product.buyWholesalePrice") },
                            { key: "agent_name", label: i18n.t("common.agent") },
                            { key: "quantity_text", label: i18n.t("common.quantity") },
                            { key: "updatedDate_text", label: i18n.t("pages.warehouse.lastUpdatedDate") },
                            { key: "actions", label: "Thao tác" },
                        ];
                    }
                    else {
                        _fields = [
                            { key: "product_picture", label: i18n.t("pages.product.picture") },
                            { key: "product_name", label: i18n.t("pages.product.productName") },                            
                            { key: "product_WholesalePrice_Text", label: i18n.t("pages.product.buyWholesalePrice") },
                            { key: "agent_name", label: i18n.t("common.agent") },
                            { key: "quantity_text", label: i18n.t("common.quantity") },
                            { key: "updatedDate_text", label: i18n.t("pages.warehouse.lastUpdatedDate") },
                            { key: "actions", label: "Thao tác" },
                        ];
                    }

                    return _fields;
                },
                set(newVal) {

                }
            },           
        },
        methods: {
            ...mapActions("inventory", ["initSearchForm", "searchInventory"]),
            showLastUpdate(item) {
                
                if (item == null || item.length === 0 || item[0].updatedDate == null)
                    return '';

                try {                   
                    return this.$moment(item[0].updatedDate, 'YYYY-MM-DD HH:mm:ss').format('DD/MM/YYYY HH:mm:ss');
                }
                catch (e) {
                    console.log('showLastUpdate exception: ', e);
                    return '';
                }
            },           
            onSearch() {                
                this.searchInfo.avaiableProductInStock = this.onlyAvaiableProductInStock;
                this.searchInventory();
            },
            onPageChanged(pageNo, reduced) {
                console.log("PageChanged", { pageNo, reduced });

                this.searchInfo.avaiableProductInStock = this.onlyAvaiableProductInStock;
                this.searchInventory(pageNo);
            },
            async asyncFind(query, id) {
                console.log("LENGTH", query.length);
                if (query.length > 1) {
                    this.isLoadingProducts = true;
                    var result = await this.$productService.searchByName(query);
                    this.isLoadingProducts = false;
                    this.products = result;
                }
                console.log("Search", { query, id });
            },

            async onAddProductToInventory(item) {
                //console.log("Xuat kho le", item);
                this.$router.push({
                    path: "/warehouses/retailinventoryadd",
                    query: { p: item.productId, ag: item.agentId },
                });
            },

            async onEditProductInStock(item) {
                //console.log("Prepare to edit product qty", item);
                this.$router.push({
                    path: "/warehouses/inventory/edit",
                    query: { p: item.product.id, ag: item.agent.id },
                });
            },

            async asyncFindAgency(query, id) {

                if (query.length > 1) {
                    this.isLoadingAgents = true;
                    var result = await this.$agentService.searchByName(query);
                    this.isLoadingAgents = false;
                    this.agents = result;
                }
            },
            onItemsPerPageChange(item) {
                localStorage.setItem('agentOrdersItemsPerPage', item)
            },

            async findAgentDefault() {
                let role = this.$store.state.auth.user.role;
                if (role === Role.Admin || role === Role.Operator) {
                    var result = await this.$agentService.getAgentById(5370);
                    if (result != null && result.data != null) {
                        this.agents = [result.data];                       
                        this.searchInfo.selectedAgents = this.agents[0];
                    }                    
                }
            }
        },
        created() {  
            //let role = this.$store.state.auth.user.role;
            let role = this.user.role;
            if (role === Role.Admin)
                this.showPurchasePrice = true;            
            else if (role === Role.Agent)
                this.showAgentList = false;
            else if (role === Role.Sale)
                this.showUpdateProductInStock = false;
           

            this.initSearchForm();
            this.onSearch();
                        
            
        },
        async mounted() {
            await this.findAgentDefault();
        },
    };
</script>
